import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import authService from "services/AuthService";
import { fetchProfile } from "services/ProfileService";

import Spinner from "./Spinner";

export const AuthContext = createContext();

const isAdmin = (profile) => {
  if (!profile) {
    return false;
  }

  for (const role of profile.roles.globalRoles) {
    if (role.name.toLowerCase() === "admin") {
      return true;
    }
  }

  return false;
};

export const useAuthContext = () => useContext(AuthContext);

export default function AuthProvider({ children }) {
  const [session, setSession] = useState();

  useEffect(() => {
    async function getUser() {
      const user = await authService.getUser();

      if (!user) {
        authService.login();
      }

      const profile = await fetchProfile();

      setSession({ user, profile, isAdmin: isAdmin(profile) });
    }

    getUser();
  }, []);

  if (!session) return <Spinner />;

  return <AuthContext.Provider value={session}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
