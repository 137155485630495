import { ApiService } from "./ApiService";

const apiService = new ApiService();

/**
 * Gets all the tenants that the current authenticated user has
 * access to.
 * @returns An array of tenants
 */
export async function fetchMyTenants() {
  //const response = await axios.get(getEndpointUri("/api/v1/tenants"));

  const response = await apiService.callApi("/api/v1/tenants");

  return response.data;
}
