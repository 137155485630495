import React from "react";
import { Spin } from "antd";

import styles from "./styles.module.css";

export default function Spinner(props) {
  return (
    <div className={styles.container}>
      <Spin {...props} />
    </div>
  );
}
