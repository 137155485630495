import React from "react";
import { Menu } from "antd";
import PropTypes from "prop-types";
import Link from "next/link";
import { LogoutOutlined } from "@ant-design/icons";

export function UserMenu({ userName, isAdmin, logout }) {
  function handleMenuClick({ _item, key }) {
    switch (key) {
      case "logout":
        logout();
    }
  }

  return (
    <Menu
      id="btnoptions"
      theme="dark"
      mode="horizontal"
      onClick={handleMenuClick}
      selectable={false}>
      <Menu.SubMenu title={userName} key="userMenu">
        {isAdmin ? (
          <Menu.Item key="Admin">
            <Link href="/admin/tenants">
              <a>Admin Area</a>
            </Link>
          </Menu.Item>
        ) : (
          ""
        )}
        <Menu.Item id="btnlogout" key="logout">
          <LogoutOutlined />
          Logout
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}

UserMenu.propTypes = {
  userName: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};
