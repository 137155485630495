import axios from "axios";
import authService from "./AuthService";

const API_HOST = process.env.NEXT_PUBLIC_API_HOST;
const API_IS_HOST = process.env.NEXT_PUBLIC_IDENTITY_HOST;

export class ApiService {
  constructor() {
    this.authService = authService;
  }

  /**
   * Calls the API taking care of sending the authentication token retrieved from the AuthService.
   * @param {string} endpoint Endpoint URL
   * @param {object} params Params to send as a query string.
   * @param {object} data Data to send as JSON in the request body.
   * @param {object} config Config object to set headers, response type and method.
   */
  callApi(endpoint, config = {}) {
    return this.authService.getUser().then((user) => {
      if (user && user.access_token) {
        return this._callApi(endpoint, user.access_token, config).catch((error) => {
          if (error.response?.status === 401) {
            return this.authService.renewToken().then((renewedUser) => {
              return this._callApi(endpoint, renewedUser.access_token, config);
            });
          }
          throw error;
        });
      } else if (user) {
        return this.authService.renewToken().then((renewedUser) => {
          return this._callApi(endpoint, renewedUser.access_token, config);
        });
      } else {
        throw new Error("user is not logged in");
      }
    });
  }

  _callApi(endpoint, token, config = {}) {
    if (!token) {
      throw new Error("Missing token argument.");
    }
    let api_host = API_HOST;
    if (endpoint.indexOf("UserManagement") > -1) {
      api_host = API_IS_HOST;
    }

    const axiosConfig = {
      method: "GET",
      responseType: "json",
      headers: {
        Accept: "application/json",
      },
      ...config,
    };

    axiosConfig.headers.Authorization = "Bearer " + token;

    return axios(api_host + endpoint, axiosConfig);
  }
}
