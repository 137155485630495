import moment from "moment";

export function checkServer() {
  return typeof window === "undefined";
}

export function isDevelopment() {
  return process.env.NODE_ENV.toLowerCase() === "development";
}

export function SaveByteArrayAsLocalFile(data, filename, contentType = "text/csv") {
  window.URL = window.webkitURL || window.URL;

  const blob = new Blob([data], { type: contentType });
  const textUrl = URL.createObjectURL(blob);
  const element = document.createElement("a");

  element.setAttribute("href", textUrl);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function parseTimestamp(timestampStr, useLocal, format) {
  let timestamp = moment.utc(timestampStr);

  if (timestamp.year() === 9999) {
    return "Present";
  }

  if (useLocal) {
    timestamp = timestamp.local();
  }

  return timestamp.format(format);
}
