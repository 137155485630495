import { ApiService } from "./ApiService";

const apiService = new ApiService();

/**
 * Gets all the systems of the tenant.
 * @param {number} tenantId The ID of the tenant.
 */
export async function getTenantSystems(tenantId) {
  if (!Number.isInteger(tenantId)) {
    throw new Error("tenantId must be an integer.");
  }

  const response = await apiService.callApi(`/api/v1/tenants/${tenantId}/systems?withSources=true`);

  return response.data;
}
