import { useRouter } from "next/router";
import useSWR from "swr";

import { fetchMyTenants } from "services/TenantService";
import { getTenantSystems } from "services/SystemService";

export function useUserTenants() {
  return useSWR("fetchMyTenants", fetchMyTenants);
}

export function useSelectedTenant() {
  const router = useRouter();

  const { tenant } = router.query;

  const { data: tenants } = useUserTenants();

  if (tenants) {
    return tenants.find((t) => t.slug == tenant);
  }

  return null;
}

export function useTenantSystems(tenantId) {
  const { data: systems } = useSWR(tenantId ? ["getTenantSystems", tenantId] : null, () =>
    getTenantSystems(tenantId)
  );

  return systems;
}
