import React from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { SWRConfig } from "swr";

import { makeServer } from "mirage";

import "antd/dist/antd.css";
import { MainLayout } from "layouts/MainLayout";

if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" });
}

const AuthProvider = dynamic(() => import("components/Common/AuthProvider"), {
  ssr: false,
});

export default function MyApp({ Component, pageProps }) {
  const Layout = Component.Layout ? Component.Layout : React.Fragment;

  return (
    <SWRConfig
      value={{
        dedupingInterval: 10000,
      }}>
      <AuthProvider>
        <MainLayout>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </MainLayout>
      </AuthProvider>
    </SWRConfig>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
};

// export default function MyApp({ Component, pageProps }) {
//   return <Component {...pageProps} />;
// }
