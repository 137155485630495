import { useContext } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Link from "next/link";
import { Layout, Space, Card } from "antd";

import { useUserTenants } from "hooks/tenants-hooks";
import { AuthContext } from "components/Common/AuthProvider";
import { TenantSelector } from "components/Common/TenantSelector";
import { UserMenu } from "components/Common/UserMenu";
import Spinner from "components/Common/Spinner";

const { Header, Content } = Layout;

import styles from "./MainLayout.module.css";
import authService from "services/AuthService";

export function MainLayout({ children }) {
  const session = useContext(AuthContext);
  const { data: tenants } = useUserTenants();
  const router = useRouter();
  const { tenant } = router.query;

  if (!session || !tenants) {
    return <Spinner />;
  }

  return (
    <Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <Header className={styles.header}>
          <Space>
            <Link href="/">
              <a>
                <img
                  className={styles.logo}
                  src="/images/doosan_logo.png"
                  alt="Doosan GridTech logo"
                />
              </a>
            </Link>
            <Link href="/">
              <a>
                <h1 className={styles.title}>Performance Analyzer</h1>
              </a>
            </Link>

            <TenantSelector tenants={tenants} selectedSlug={tenant} />
          </Space>
          <Space>
            <UserMenu
              userName={session.profile.name}
              isAdmin={session.isAdmin}
              logout={() => authService.logout()}
            />
          </Space>
        </Header>
        <Content className={styles.content}>
          <Card className={styles.mainCard} bordered>
            {children}
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
