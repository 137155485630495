import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Menu } from "antd";

export function TenantSelector({ tenants, selectedSlug }) {
  if (tenants === null) {
    return null;
  }

  const customerTitle = selectedSlug
    ? tenants.find((t) => t.slug == selectedSlug).name
    : "Choose Customer";
  const subMenuTitle = <span>{customerTitle}</span>;

  let subMenu = (
    <Menu.SubMenu
      key="no-tenant-submenu"
      title={subMenuTitle}
      style={{ textAlign: "center", width: "11em" }}>
      <Menu.Item key="no-customers">No Customers</Menu.Item>
    </Menu.SubMenu>
  );

  if (tenants.length > 0) {
    subMenu = (
      <Menu.SubMenu
        key="tenant-submenu"
        title={subMenuTitle}
        style={{ textAlign: "center", width: "11em" }}>
        {tenants.map((t) => (
          <Menu.Item key={`/tenant/${t.slug}`} data-type="tenantSelectorItem">
            <Link href={`/tenant/${t.slug}`}>
              <a id={`tenantSelectorItem-${t.name}`}>{t.name}</a>
            </Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    );
  }

  return (
    <div>
      <Menu
        id="tenantSelector"
        theme="dark"
        mode="horizontal"
        selectedKeys={[`/tenant/${selectedSlug}`]}>
        {subMenu}
      </Menu>
    </div>
  );
}

TenantSelector.propTypes = {
  tenants: PropTypes.array,
  selectedSlug: PropTypes.string,
};
