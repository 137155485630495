import { Log, UserManager } from "oidc-client";
import { checkServer, isDevelopment } from "utils";

const IDENTITY_HOST = process.env.NEXT_PUBLIC_IDENTITY_HOST;
const CLIENT_ROOT = process.env.NEXT_PUBLIC_CLIENT_HOST;

class AuthService {
  constructor() {
    this.settings = {
      authority: IDENTITY_HOST,
      client_id: "pa-web-client-oidc",
      redirect_uri: `${CLIENT_ROOT}/signin-callback.html`,
      silent_redirect_uri: `${CLIENT_ROOT}/silent-renew.html`,
      post_logout_redirect_uri: CLIENT_ROOT,
      response_type: "code",
      scope: "openid profile api1",
    };

    if (!checkServer()) {
      this.userManager = new UserManager(this.settings);
    }

    if (isDevelopment()) {
      Log.logger = console;
      Log.level = Log.INFO;
    }
  }

  getUserManager() {
    if (!this.userManager) {
      if (!checkServer()) {
        this.userManager = new UserManager(this.settings);
      }
    }

    return this.userManager;
  }

  getUser() {
    if (!this.userManager) this.userManager = new UserManager(this.settings);

    return this.getUserManager().getUser();
  }

  login() {
    return this.getUserManager().signinRedirect();
  }

  renewToken() {
    return this.getUserManager().signinSilent();
  }

  logout() {
    return this.getUserManager().signoutRedirect();
  }
}

const authService = new AuthService();

export default authService;
